<template>
    <PageHeader :title="title" :items="items" />

    <tablecustom 
      @search="searchB" 
      :datafilter="filterAll" 
      :columns="columns" 
      :rows="filteredItems" 
      :createdform="this.perms['1601'] || this.perms['2000']" 
      :removeitem="this.perms['1603'] || this.perms['2000']"
      @create="create" 
      @change="change" 
      @clearFilter="clearFilter" 
      @open="open" 
      @removeAll="removeAll"
    />
    
    <viewcateg 
      v-if="showModal == true"  
      @close="showModal = false" 
      :shortapplic="cardApplic" @remove="remove"
    ></viewcateg>
    
    <created 
      v-if="modalCreatedBox == true" 
      @close="modalCreatedBox = false" 
    />

  </template>
  
  <script>
  import PageHeader from "@/components/page-header";
  import { Attributes } from '@/API.js';
  import tablecustom from '@/components/globaltable/index';
  import created from './create'
  import viewcateg from './view'
  import { storeS } from "@/store";
  
  let apiServe = new Attributes();
  
  export default {
    components: {
      PageHeader,
      created,
      viewcateg,
      tablecustom
    },
    data() {
      return {
        title: this.$t('attributes'),
        search: '',
        modalCreatedBox: false,
        statuscreated: true,
        items: [
          {
            text: this.$t('attributes'),
            href: "/",
          },
          {
            text: this.$t('all'),
            active: true,
          },
        ],
        showModal: false,
        columns: [
          {
            name: this.$t('ID'),
            text: "id",
            align: "left",
          },
          {
            name: this.$t("Point_name"),
            text: "title",
            align: "left",
          },
        ],
        rows: [],
        cardApplic: {}
      };
    },
    created() {
      this.getdata();
    },
    methods: {
      getdata(){
        apiServe.getAllAttrs().then(result => {
          this.rows = result;
        })
      },
      create: function(){
        this.modalCreatedBox = true
      },
      open: function(e){
        apiServe.getAttr(e.id).then(res => {
          this.cardApplic = res;
          this.showModal = true;
        })
      },
      remove: function(e, form){
        // // ("re", e + form)
        apiServe.deleteAttr(e.id).then(res => {
          if(res){
            this.showModal = false;
            this.getdata();
          }
        })
        this.$toast.success(this.$t('Removed'));
      },
      removeAll: function(e){
        for(var item in e) {
          apiServe.deleteAttr(e[item]).then(res => {
            // ("res", res)
            this.getdata();
            if(res){
              this.$toast.success(this.$t('Removed'));
              this.getdata();
            }
          })
        }
      },
      searchB(e){
        this.search = e
      },
    },
    computed: {
      filteredItems: function() {
          return this.rows
              // По полю поиска
              .filter(item => {
                  return this.search == '' || item.title.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
              })
      },
      user() {
        return storeS.userbase
      },
      perms() {
        return storeS.perms
      }
    },
    mounted() {
      this.eventBus.on('saveAttr', (status) => {
        if(status == true) {
          this.getdata();
        }
      })
    }
  };
  </script>
  
  <style scoped>
  .tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
  }
  </style>
  